import React from "react";

import DefaultLayout from "../../../layouts/default";

const Rehabilitation = () => (
  <DefaultLayout title="Rehabilitation">
    <div className="white wicare verySpecialSubNavMuchUnique">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productText">
              <span className="line">WiCare|Doc</span>
              <span className="line">in der Rehabilitation</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links fourLinksReha">
            <a className="blue" href="/dokumentationsloesungen/wicare-doc-r/">
              <li>Rehabilitation</li>
            </a>
            <a href="/dokumentationsloesungen/wicare-le/" className="red">
              <li>Leistungserfassung</li>
            </a>
            <a href="/dokumentationsloesungen/wicare-lep/" className="green">
              <li>
                LEP<sup>&#174;</sup>
              </li>
            </a>
            <a className="purple" href="/dokumentationsloesungen/wicare-tacs/">
              <li>
                tacs<sup>&#174;</sup>
              </li>
            </a>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <section>
          <div className="headerImg">
            <img
              className="js-img-size-from-parent"
              width="100%"
              src="https://wigasoft-prod.imgix.net/stimmungsbilder/wcd-r-header-HiRes.jpg?q=50&w=1200&h=400"
            />
          </div>
        </section>

        <section className="wrapper">
          <div className="uebersichtsItem">
            <div className="text">
              <a href="/dokumentationsloesungen/wicare-doc-r/">
                <img
                  src="/assets/images/logos/icon-wcd.svg"
                  className="padding-25-top is-visible-mobile"
                  width="100px"
                />
              </a>
              <h1>WiCare|Doc-R</h1>
              <h4>Patientendokumentation für Rehakliniken</h4>
              <a href="/dokumentationsloesungen/wicare-doc-r/">
                <button className="margin-25-top large notThatLarge higherWidth">
                  &nbsp;&nbsp;&nbsp;Produktdetails&nbsp;&nbsp;&nbsp;
                </button>
              </a>
            </div>
            <div className="icon is-visible-tablet">
              <a href="/dokumentationsloesungen/wicare-doc-r/">
                <img src="/assets/images/logos/icon-wcd.svg" />
              </a>
            </div>
          </div>
          <hr />
          <div className="uebersichtsItem">
            <div className="text">
              <a href="/dokumentationsloesungen/wicare-le/">
                <img
                  src="/assets/images/logos/WCN-LE.svg"
                  className="padding-25-top is-visible-mobile"
                  width="100px"
                />
              </a>
              <h1>WiCare|now LE</h1>
              <h4>
                Die webbasierte Leistungserfassungslösung für verschiedene
                Fachbereiche
              </h4>
              <a href="/dokumentationsloesungen/wicare-le/">
                <button className="margin-25-top large notThatLarge higherWidth">
                  &nbsp;&nbsp;&nbsp;Produktdetails&nbsp;&nbsp;&nbsp;
                </button>
              </a>
            </div>
            <div className="icon is-visible-tablet">
              <a href="/dokumentationsloesungen/wicare-le/">
                <img src="/assets/images/logos/WCN-LE.svg" />
              </a>
            </div>
          </div>
          <hr />
          <div className="uebersichtsItem">
            <div className="text">
              <a href="/dokumentationsloesungen/wicare-lep/">
                <img
                  src="/assets/images/logos/WCN-LEP.svg"
                  className="padding-25-top is-visible-mobile"
                  width="100px"
                />
              </a>
              <h1>
                WiCare|now LEP<sup>&#174;</sup>
              </h1>
              <h4>Leistungserfassung in der Pflege</h4>
              <a href="/dokumentationsloesungen/wicare-lep/">
                <button className="margin-25-top large notThatLarge higherWidth">
                  &nbsp;&nbsp;&nbsp;Produktdetails&nbsp;&nbsp;&nbsp;
                </button>
              </a>
            </div>
            <div className="icon is-visible-tablet">
              <a href="/dokumentationsloesungen/wicare-lep/">
                <img src="/assets/images/logos/WCN-LEP.svg" />
              </a>
            </div>
          </div>
          <hr />
          <div className="uebersichtsItem">
            <div className="text">
              <a href="/dokumentationsloesungen/wicare-tacs/">
                <img
                  src="/assets/images/logos/WCN-tacs.svg"
                  className="padding-25-top is-visible-mobile"
                  width="100px"
                />
              </a>
              <h1>
                WiCare|now tacs<sup>&#174;</sup>
              </h1>
              <h4>Das Controlling im Griff</h4>
              <a href="/dokumentationsloesungen/wicare-tacs/">
                <button className="margin-25-top large notThatLarge higherWidth">
                  &nbsp;&nbsp;&nbsp;Produktdetails&nbsp;&nbsp;&nbsp;
                </button>
              </a>
            </div>
            <div className="icon is-visible-tablet">
              <a href="/dokumentationsloesungen/wicare-tacs/">
                <img src="/assets/images/logos/WCN-tacs.svg" />
              </a>
            </div>
          </div>
        </section>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/rehabilitation/">
                    Rehabilitation
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>

                  <li>
                    <a href="/impressum/">Impressum</a>
                  </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default Rehabilitation;
